import { catchError } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./conf.service";
import * as i3 from "./http.service";
export class AuthService {
    constructor(http, confService, httpService) {
        this.http = http;
        this.confService = confService;
        this.httpService = httpService;
    }
    checkMasterKey() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/auth/check-key', this.httpService.getTokenHeaders())
            .pipe(catchError(this.httpService.formatErrors));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfService), i0.ɵɵinject(i3.HttpService)); }, token: AuthService, providedIn: "root" });
