import {Component, OnInit, Renderer2} from '@angular/core';
import { AuthInterceptor } from './core/interceptors/auth.interceptors';
import { AdminService } from './core/services/admin.service';
import { PersonDtoOld} from './model/PersonDto';
import { Person} from './model/person';
import {GuideService} from './core/services/guide.service';
import {takeUntil} from 'rxjs/operators';
import {Gurules} from './model/gurules';
import {GuideTranslation} from './model/guide';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {ActionAudit, BookingGuestData, PropertyBooking} from './model/property-booking';
import {ActionSetup, OtaListing, Property} from './model/property';
import {Tags, Translations} from './model/tags';
import {TagsService} from './core/services/tags.service';
import { HttpService } from '../app/core/services/http.service';
import {AuthService} from "./core/services/AuthService";




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  checker: boolean = true;

  title = 'YAAGO-AdminPortal';

  properties: Property[] = [];
  personToClone: PersonDtoOld;
  showConfirmClone = false;
  currentLogins: PersonDtoOld[];
  filteredLogins: PersonDtoOld[] = [];
  currentUser: PersonDtoOld | null = null;
  viewPropertyDetails = false;
  viewBookingDetails = false;
  viewUserAgents = false;
  emptyCalendar = false;
  viewKey = true;
  // viewKey = false;
  myKey: string;
  viewGurules = false;
  openAddGurules = false;
  openEditGurule = false;
  openDeleteGurule = false;
  showConfirmDelete = false;
  showActionAudit = false;
  currentUserToDelete: Person;
  numberOfProperties: BigInteger;
  numberOfBookings: BigInteger;
  showLoading = false;

  viewTags = false;
  viewPermissions = false;
  openAddTags = false;
  openEditTags = false;
  openDeleteTags = false;
  AllTag: Tags[];
  AllArrayTags: Tags[];
  tags: Translations[] = [];
  FilteredTags: Tags[];
  viewTagCategory: Tags[] = [];

  guRules: Gurules[] = [];
  currentRule: Gurules;
  Array: GuideTranslation[] = [];

  FilteredGuideGurule: Gurules[] = [];
  FilteredGuideGuruleEnglish: Gurules[] = [];

  newGurule: Gurules = new Gurules();
  inGuide: GuideTranslation[] = [];
  newTranslation: GuideTranslation = new GuideTranslation();

  searchFilter = '';
  viewGurulesLanguages: GuideTranslation[] = [];

  currentPropertyBooking: PropertyBooking;
  currentAction: ActionAudit[];
  currentActionProperty: ActionSetup[];

  search: '';



  constructor(private adminService: AdminService,
              private authInterceptor: AuthInterceptor,
              private authService: AuthService,
              private guideService: GuideService,
              private router: Router,
              private tagsService: TagsService,
              private httpService : HttpService
  ) {

  }

  private _onDestroy = new Subject();

  ngOnInit(): void {
    console.log("ngOnInit");
    if (localStorage.getItem('adminKey') === 'Infine2022!' || localStorage.getItem('adminKey') === 'superInfine2022!') {
      this.viewKey = false;
    }
    this.tagsService.goBackSub.subscribe(res=>{
      this.goBack();
    })

  }

  enterKey() {
    // if (this.myKey.trim() !== '') {
      this.authInterceptor.setAdminKey(this.myKey);
      this.authService.checkMasterKey().subscribe(r => {
        if (this.checker) {
          this.viewKey = false;
          this.router.navigate(['/users']);
        } else {
          this.httpService.setAdminKey(this.myKey);
        }
      }, err => {

      });

  }

  // GURULE

  getGurules() {
    this.viewGurules = true;
    this.guideService.getGuRules().pipe(takeUntil(this._onDestroy)).subscribe(rules => {
      this.guRules = rules;
      if (this.guRules != null && this.guRules.length > 0 && this.guRules[0].translations != null && this.guRules[0].translations.length > 0) {
        this.viewGurulesLanguages = this.guRules[0].translations;
      }
    });
  }

  removeKey() {
    localStorage.removeItem('adminKey');
    this.viewKey = true;
  }



  goBack() {
    this.viewGurules = false;
    this.openAddGurules = false;
    this.openEditGurule = false;
    this.openDeleteGurule = false;
    this.showConfirmClone = false;
    this.showConfirmDelete = false;
    this.viewTags = false;
    this.openAddTags = false;
    this.openEditTags = false;
    this.openDeleteTags = false;
    this.viewPermissions = false;
  }




}
