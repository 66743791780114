import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfService} from "./conf.service";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http : HttpClient,
              private confService : ConfService,
              private httpService : HttpService
  ){}

  public checkMasterKey(): Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/auth/check-key' , this.httpService.getTokenHeaders())
      .pipe(catchError(this.httpService.formatErrors))
  }
}
