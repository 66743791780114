import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-config/core";
export class ConfService {
    constructor(config) {
        this.config = config;
    }
    getBackendApiEndPoint() {
        try {
            const { apiEndpoint } = environment;
            //const url = apiEndpoint || this.config.getSettings('system.apiEndPoint');
            // const url =  "http://localhost:8080/";
            // const url =  "https://app.yaago.com/";
            const url = "";
            if (url == null || url.trim() === '') {
                if (window.location.origin.indexOf("staging") > -1) {
                    return window.location.origin.replace("admin.staging", "staging") + "/";
                }
                else if (window.location.origin.indexOf("demo") > -1) {
                    return window.location.origin.replace("admin.demo", "demo") + "/";
                }
                else {
                    return window.location.origin.replace("admin.", "app.") + "/";
                }
            }
            else {
                return url;
            }
        }
        catch (exception) {
            if (window.location.origin.indexOf("staging") > -1) {
                return window.location.origin.replace("admin.staging", "staging") + "/";
            }
            else if (window.location.origin.indexOf("demo") > -1) {
                return window.location.origin.replace("admin.demo", "demo") + "/";
            }
            else {
                return window.location.origin.replace("admin.", "app.") + "/";
            }
        }
    }
}
ConfService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfService_Factory() { return new ConfService(i0.ɵɵinject(i1.ConfigService)); }, token: ConfService, providedIn: "root" });
